import { Outlet } from 'react-router';
import ErrorBoundary from './ErrorBoundary';
import Header from './Header';
import ScrollToTop from './ScrollToTop';
import CustomDrawerProvider from '~/CustomDrawerProvider';

const RouterRootComponent = () => {
  return (
    <>
      <Header />
      <ErrorBoundary>
        <ScrollToTop>
          <CustomDrawerProvider>
            <Outlet />
          </CustomDrawerProvider>
        </ScrollToTop>
      </ErrorBoundary>
    </>
  );
};

export default RouterRootComponent;
