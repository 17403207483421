import React from 'react';
import AppLoader from './AppLoader';
import PropTypes from 'prop-types';

const LoadModule = ({ children }) => {
  return <React.Suspense fallback={<AppLoader />}>{children}</React.Suspense>;
};

LoadModule.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LoadModule;
