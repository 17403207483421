import ValidBaseUrl from "~/enums/ValidBaseUrl";

export default function getBaseUrl() {

  const pathname = window.location.pathname;
  const { ALL_PAGES } = ValidBaseUrl;

  // Split the pathname by '/'
  const pathSegments = pathname.split('/').filter(Boolean);  // Filter out empty segments

  // Loop through each base URL and check if the path starts with the base URL
  for (const base of ALL_PAGES.valid_Url) {
    const index = pathSegments.indexOf(base);
    if (index !== -1) {
      // Rebuild the path up to the matching segment
      return `/${pathSegments.slice(0, index).join('/')}`;
    }
  }

  return pathname;
}
