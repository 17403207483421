import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Await, useLoaderData } from 'react-router';
import AppLoader from './components/AppLoader';
import ErrorMessageDictionary from './components/ErrorMessageDictionary';

const DictionaryData = createContext(null);

export default function DictionaryProvider({ children }) {
  const { data: dataDictionary } = useLoaderData();
  const [dictionary, setDictionary] = useState([]);

  dataDictionary.then(resolved => {
    setDictionary(resolved);
  });

  const getDictionary = ({ key, transform = null }) => {
    if (typeof transform !== 'function') return dictionary[key] ?? [];
    return transform(dictionary[key] || []);
  };

  const dictionaryInfo = {
    data: dictionary,
    getDictionary,
  };

  return (
    <React.Suspense fallback={<AppLoader />}>
      <Await
        resolve={dataDictionary}
        errorElement={<ErrorMessageDictionary />}>
        <DictionaryData.Provider value={dictionaryInfo}>{children}</DictionaryData.Provider>
      </Await>
    </React.Suspense>
  );
}

export const useDictionaryData = () => {
  const methods = useContext(DictionaryData);
  return methods;
};

DictionaryProvider.propTypes = {
  dictionary: PropTypes.object,
  children: PropTypes.object,
};
