import PropTypes from 'prop-types';
import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import { create } from 'react-modal-promise';
import getBaseUrl from './utils/getBaseUrl';

const CustomDrawer = createContext({});
// eslint-disable-next-line react-refresh/only-export-components
export function useDrawerDetails() {
  return useContext(CustomDrawer);
}

export default function CustomDrawerProvider({ children }) {
  const { drawerId: pageReloadDrawerId, drawerModalId: pageReloadDrawerModalId } = useParams();
  const location = useLocation();
  const basePath = useRef(null);
  const [customDrawerId, setCustomDrawerId] = useState(pageReloadDrawerId);
  const [customDrawerModalId, setCustomDrawerModalId] = useState(pageReloadDrawerModalId);
  const closeDrawerId = useRef(0);
  const drawerRef = useRef(null);
  const registerDrawerDetails = useRef(new Map());

  useEffect(() => {
    const baseUrl = getBaseUrl();
    basePath.current = baseUrl;
    if (!pageReloadDrawerId) {
      setCustomDrawerId('');
      setCustomDrawerModalId(0);
      closeDrawerId.current = null;
    }
  }, [location.pathname]);

  useEffect(() => {
    closeAndOpenRelevantDrawer();
    if (customDrawerId || customDrawerModalId) {
      const baseUrl = getBaseUrl();
      basePath.current = baseUrl;
    }
  }, [customDrawerId, customDrawerModalId]);

  const registerDrawer = ({
    id,
    component,
    validateDetails,
    options,
    props,
    isDrawerOpen = false,
  }) => {
    const modalContainer = create(component);

    const drawerOptions = {
      id,
      openModal: () =>
        openDrawer({
          modalContainer,
          options: { ...options, ref: drawerRef },
          props,
          id,
        }),
      validateDetails,
      isDrawerOpen,
      drawerRef,
    };

    registerDrawerDetails.current.set(id, {
      drawerOptions,
    });

    closeAndOpenRelevantDrawer();
  };

  const closeAndOpenRelevantDrawer = () => {
    closeAlreadyOpenDrawer().then(() => {
      registerDrawerDetails.current.forEach(value => {
        const { drawerOptions } = value || {};
        const { id, validateDetails, isDrawerOpen } = drawerOptions || {};
        const checkIsDrawerValid =
          validateDetails({
            customDrawerId,
            customDrawerModalId,
          }) && !isDrawerOpen;
        if (checkIsDrawerValid) {
          drawerOptions.isDrawerOpen = true;
          closeDrawerId.current = id;
          drawerOptions.openModal();
        }
      });
    });
  };

  const openDrawer = ({ modalContainer, options = {}, props }) => {
    return modalContainer(options)
      .then(result => {
        const { successDrawerCallback } = props || {};
        if (successDrawerCallback) successDrawerCallback(result);
        setCustomDrawerId('');
        setCustomDrawerModalId(null);
      })
      .catch(err => {
        if (!err) return;
        setCustomDrawerId('');
        setCustomDrawerModalId(null);
      });
  };

  const closeAlreadyOpenDrawer = () => {
    return new Promise(resolve => {
      const alreadyOpenDrawer = registerDrawerDetails.current.get(closeDrawerId.current);
      if (alreadyOpenDrawer) {
        const { drawerOptions } = alreadyOpenDrawer;
        const { drawerRef } = drawerOptions || {};
        if (drawerRef.current) {
          closeDrawerId.current = 0;
          drawerOptions.isDrawerOpen = false;
          if (!customDrawerId) window.history.replaceState({}, '', basePath.current);
          drawerRef.current.reject();
          setTimeout(() => resolve(), 250);
        }
      } else {
        resolve();
      }
    });
  };

  function updateDrawerHistory({ modalName, modalId, newBasePath }) {
    const updatedPath = modalId
      ? `${newBasePath ? newBasePath : basePath.current}/${modalName}/${modalId}`
      : `${newBasePath ? newBasePath : basePath.current}/${modalName}`;

    window.history.pushState({}, '', updatedPath);
    if (modalName) setCustomDrawerId(modalName);
    if (modalId) setCustomDrawerModalId(modalId);
  }

  const unregisterDrawerDetails = clearAllId => {
    if (!Array.isArray(clearAllId)) {
      clearAllId = [].concat(clearAllId);
    }

    // Iterate over the array and delete each id from registerDrawerDetails
    clearAllId.forEach(id => {
      registerDrawerDetails.current.delete(id);
    });
  };

  const DrawerProviderValues = {
    registerDrawer,
    unregisterDrawerDetails,
    updateDrawerHistory,
    registerDrawerDetails,
    customDrawerId,
    customDrawerModalId,
  };

  return <CustomDrawer.Provider value={DrawerProviderValues}>{children}</CustomDrawer.Provider>;
}

CustomDrawerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
