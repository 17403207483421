import { useEffect } from 'react';
import { KeycloakProvider } from 'keycloak-react-web';
import AppProviders from '~/AppProviders';
import { KeycloakInitOptions, getKeycloakClient } from '~/auth';
import LoginScreen from './components/LoginScreen';
import LocalStorageInitialStateNames from './enums/LocalStorageInitialStateNames';
import useLocalStorage from './hooks/useLocalStorage';
import AppRoutes from './AppRoutes';
import { ClearAnalyticsExpiredData } from './utils/ClearAnalyticsExpiredData';

export default function App() {
  const currentUrl = window.location.href;
  const redirectTo = currentUrl.split('?redirect_to=/')[1];
  if (redirectTo) window.location.href = import.meta.env.VITE_APP_BASE_URL + redirectTo;
  const extractAccountName = url => {
    const regex = /\/account\/([^/?#]*)/; // Pattern to find text between /account/ and the next /
    const match = url.match(regex); // Get the matched results
    if (match) {
      return match[1]; // Extracted group
    }
    return null; // If no match found
  };
  const accountName = extractAccountName(currentUrl) || '';
  const [realmName, setRealmName] = useLocalStorage(
    LocalStorageInitialStateNames.REALM_NAME,
    accountName
  );
  if (accountName) localStorage.setItem(LocalStorageInitialStateNames.REALM_NAME, accountName);

  useEffect(() => {
    ClearAnalyticsExpiredData();
  }, []);

  return (
    <LoginScreen state={[realmName, setRealmName]}>
      <KeycloakProvider
        client={getKeycloakClient(realmName)}
        initOptions={KeycloakInitOptions}>
        <AppProviders>
          <AppRoutes />
        </AppProviders>
      </KeycloakProvider>
    </LoginScreen>
  );
}
