import PropTypes from 'prop-types';

export default function Card({ logo, description }) {
  return (
    <div className="box">
      <img
        src={logo}
        alt="logo"
      />
      <div>
        <span>{description}</span>
      </div>
    </div>
  );
}

Card.propTypes = {
  logo: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};
