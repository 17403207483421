import { forwardRef } from 'react';
import { IMaskInput } from 'react-imask';
const TextMaskCustom = forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, mask, ...other } = props;
  const { mask: maskType } = mask;
  return (
    <IMaskInput
      {...other}
      {...props.mask}
      mask={maskType}
      onAccept={value => onChange(value)}
      inputRef={ref}
    />
  );
});

export default TextMaskCustom;
