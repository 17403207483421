
export function ClearAnalyticsExpiredData() {
    let storedData = JSON.parse(localStorage.getItem("analytics_redirect_params")) || {};
    const currentTime = Date.now();

    if (Object.keys(storedData).length === 0) return {};

    // Remove expired entries
    Object.keys(storedData).forEach(uid => {
        if (storedData[uid].expiry <= currentTime) {
            delete storedData[uid];
        }
    });

    // Update localStorage with valid data
    localStorage.setItem("analytics_redirect_params", JSON.stringify(storedData));

    return storedData;
}