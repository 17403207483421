import AnalyticsFavIcon from '../assets/analytics-favicon.ico';
import AnalyticsAppLogo from '../assets/analytics-icon-32x32.png';
import ManageAppLogo from '../assets/manage-app-icon.png';
import ManageFavIcon from '../assets/manage-favicon.ico';

export const AppLogo = Object.freeze({
  MANAGE: ManageAppLogo,
  ANALYTICS: AnalyticsAppLogo,
});

export const FavIcon = Object.freeze({
  MANAGE: ManageFavIcon,
  ANALYTICS: AnalyticsFavIcon,
});
