const ValidBaseUrl = Object.freeze({
    ALL_PAGES: {
        valid_Url: ["settings", "create-clone", "order", "ad", "create-marketer"]
    },
    DIRECT_DEAL_CREATE: {
        valid_Url: ["settings", "order"],
    },
    DIRECT_DEAL_MANAGE: {
        valid_Url: ["settings", "create-clone", "order", "ad"],
    },
    ADX_DEAL: {
        valid_Url: ["settings", "order"],
    },
    NETWORK_LINE_ITEM: {
        valid_Url: ["settings"],
    },
    THIRD_PARTY_TAG: {
        valid_Url: ["settings"],
    },
    MARKETER_SEGMENT: {
        valid_Url: ["create-marketer", "create-marketers"],
    }
})

export default ValidBaseUrl;