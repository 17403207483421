import useDocumentTitle from '~/hooks/useDocumentTitle';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

export default function ErrorMessageDictionary() {
  useDocumentTitle({
    documentTitle: 'Manage: Dictionary failed to load...',
  });
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}>
      <Paper
        elevation={3}
        style={{ padding: '20px', textAlign: 'center' }}>
        <Typography
          variant="h5"
          color="error">
          Oops! Something went wrong.
        </Typography>
        <Typography variant="body1">
          We are sorry, but it seems that there is an issue with our website. Please try again
          later.
        </Typography>
        <Typography variant="body1">
          If the problem persists, contact our support team for assistance at{' '}
          <b>example@support.com</b>.
        </Typography>
      </Paper>
    </div>
  );
}
