/* eslint-disable react/no-children-prop */
import PropTypes from 'prop-types';
import useMediaQuery from '@mui/material/useMediaQuery';
import MobieRealmView from './MobileRealmView';
import CheckRealmName from './CheckRealmName';

const { VITE_KEYCLOAK_REALM } = import.meta.env;

export default function LoginScreen({ state, children }) {
  const isMobileView = useMediaQuery('(max-width:768px)');
  const isRealmAvailable = localStorage.getItem('realm_name') || VITE_KEYCLOAK_REALM;

  return (
    <>
      {isMobileView && !isRealmAvailable ? (
        <MobieRealmView />
      ) : (
        <CheckRealmName
          state={state}
          children={children}
        />
      )}
    </>
  );
}

LoginScreen.propTypes = {
  state: PropTypes.array,
  children: PropTypes.node,
};
