import AppsIcon from '@mui/icons-material/Apps';
import { ClickAwayListener, Grow, MenuList, Paper, Popper } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import UserApps from '~/config/UserApps';
import useAppMenu from '~/hooks/useAppMenu';
import { useLocation } from 'react-router-dom';

export default function AppMenu() {
  const { open, anchorElement, handleClick, handleClose, handleAppClick } = useAppMenu();
  const { pathname } = useLocation();

  return (
    <>
      <Box
        className="app-container"
        sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title="App Menu">
          <IconButton
            onClick={handleClick}
            size="big"
            sx={{ ml: 2, p: 0 }}
            aria-controls={open ? 'app-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}>
            <AppsIcon
              className="appDrawerIcon"
              style={{ color: '#f5f5f5' }}
              fontSize="large"
            />
          </IconButton>
        </Tooltip>
      </Box>
      {/* <Menu
        anchorEl={anchorElement}
        id="app-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          className: 'header-menu',
          elevation: 0,
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem>Ding dong</MenuItem>
      </Menu> */}
      <Popper
        open={open}
        anchorEl={anchorElement}
        role={undefined}
        transition
        disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
            <Paper
              className="appDrawer"
              sx={{ borderRadius: 0 }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="app-menu">
                  {Object.keys(UserApps).map(appId => {
                    const app = UserApps[appId];
                    return (
                      <IconButton
                        className="iconPadding"
                        key={app.id}
                        onClick={e => handleAppClick(e, app.url)}
                        disabled={pathname === app.url}>
                        <div>
                          <div style={{ width: 72 }}>{app.icon}</div>
                          <div className="appTitle">{app.title}</div>
                        </div>
                      </IconButton>
                    );
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
