export default function fetchingDataDictionary(token, fetchDataDictionary) {

    function checkIfUrlInArray(data) {
        return data.map(el => {
            if (!el.data || el.url) {
                delete el.url;
                el.data = [];
            }
            return el;
        })
    }

    function fetchExternalDataUrls(data) {
        const isDataArray = Array.isArray(data) && data.length > 0;
        if (!isDataArray) return Promise.resolve(data);
        const isDataContainingExternalDictionary = data?.filter(dictionary => dictionary.url);
        if (!isDataContainingExternalDictionary.length) return Promise.resolve(data);

        const externalURLResponse = isDataContainingExternalDictionary.map(async dictionary => {
            const res = await fetchDataDictionary([dictionary.url]);
            const findIndex = data.findIndex(item => item.url === dictionary.url);
            data[findIndex].data = res.data;
            delete dictionary.url;
            return data;
        })

        return Promise.allSettled(externalURLResponse).then((results) => {
            // TODO: Optimise this later 
            const isAllUrlFailed = results.every(result => result.status === 'rejected');
            if (isAllUrlFailed) return Promise.resolve(checkIfUrlInArray(data));
            const urlSuccess = results.filter(result => result.status === 'fulfilled');
            return Promise.resolve(checkIfUrlInArray(urlSuccess[0].value));
        })
    }

    const fetchData = async (apis) => {
        const promiseArray = Object.entries(apis).map(async ([key, api]) => {
            const response = await fetchDataDictionary([api]);
            const result = await fetchExternalDataUrls(response.data); //fetchExternalDictionary
            return { [key]: result };
        });
        const data = await Promise.all(promiseArray).then(results => {
            return Object.assign({}, ...results);
        });
        return data;
    };

    return { fetchData }
}