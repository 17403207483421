import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    // used for the links, buttons and important texts,
    primary: {
      main: '#084384',
    },
  },
  typography: {
    fontFamily: ['Roboto', 'sans-serif'].join(','),
  },
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: () => ({
          paddingTop: 75,
          paddingLeft: 20,
          paddingRight: 20,
          paddingBottom: 20,
          minWidth: 250,
        }),
      },
    },
  },
  global: {
    pageLeftMargin: 30,
    pageRightMargin: 30,
  },
});

export default theme;
