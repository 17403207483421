import { AppBar, Box, Toolbar, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { useAppContext } from '~/AppProviders';
import AppMenu from './AppMenu';
import Hamburger from './Hamburger';
import ProfileMenu from './ProfileMenu';
import { useMatch } from 'react-router-dom';
import { AppLogo } from '~/enums/AppLogo';

const {
  VITE_BRAND_ICON: BrandIcon,
  VITE_HEADER_LOGO: BrandLogo,
  VITE_BRAND_NAME: BrandName,
  VITE_BRAND_TOOLTIP: BrandTooltip,
} = import.meta.env;

export default function Header() {
  // TODO: We need to come up with better solution for to show hamburger or not and the path identification on which page is open
  const { appTitle } = useAppContext();
  // Info: In feature if the path change for analytics or any sub-domain comes then we need to revist this
  const isAnalyticsPageOpen = useMatch('/analytics');
  const { MANAGE } = AppLogo;

  return (
    <AppBar style={{ position: 'fixed' }}>
      <Toolbar>
        {!isAnalyticsPageOpen && <Hamburger />}
        <Link
          className="app_link"
          to={isAnalyticsPageOpen ? '/analytics' : '/'}>
          <Box
            component="span"
            className="app-icon">
            <img
              alt={appTitle}
              src={MANAGE}
              id="app-logo"
            />
            <Typography
              sx={{ color: '#fff', paddingLeft: '8px' }}
              variant="h5"
              id="app-title">
              {appTitle}
            </Typography>
          </Box>
        </Link>

        <Box
          component="span"
          className="center-container brand-icon">
          {BrandIcon && (
            <img
              src={BrandIcon || ''}
              alt={BrandTooltip || ''}
            />
          )}
          {BrandLogo && (
            <img
              src={BrandLogo || ''}
              alt={BrandTooltip || ''}
            />
          )}
          {BrandName || ''}
        </Box>
        {/* TODO: This feature will need to enabled in the future, when the API is ready */}
        {/* <Paper className="search-container">
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search"
              inputProps={{ 'aria-label': 'Search' }}
            />
            <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
              <SearchIcon />
            </IconButton>
            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            <IconButton
              color="primary"
              sx={{ p: '10px' }}
              aria-label="Advanced search"
            >
              <TuneIcon />
            </IconButton>
          </Paper> */}
        <AppMenu />
        <ProfileMenu />
      </Toolbar>
    </AppBar>
  );
}
